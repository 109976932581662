<template>
<div class="grid crud-demo">
    <Loader v-model="loading" />
    <Helper v-model="ledgerAccountHelper" header="Buscar Cuenta Contable" :headers="ledgerAccountHeaders" :rows="ledgerAccounts" @selected="selectLedgerAccount" />
    <div class="col-12">
        <div class="card">
            <Toast />
            <h2>Proveedor</h2>
            <BasicFormToolbar @new="openNew"  @save="save" @list="list" :actions="['new', 'save', 'list']" />
            <div class="grid formgrid p-fluid">
                <div class="col-12">
                    <TabView ref="tab">
                        <TabPanel header="Datos Generales">
                            <Panel header="Datos Generales">
                              <div class="p-fluid formgrid grid">
                                  <FormInputText wrapperClass="field col-8" label="Nombre" v-model="entity.name" :valid="validate.validations.name" />
                                  <FormInputText wrapperClass="field col-4" label="Nombre Corto" v-model="entity.key_name" :valid="validate.validations.key_name" :tooltip="'¿Que es nombre corto?'" />
                                  <FormInputText wrapperClass="field col-4" label="Nombre Comercial" v-model="entity.trade_name" :valid="validate.validations.trade_name" />
                                  <FormInputText wrapperClass="field col-4" label="RFC" v-model="entity.rfc" :valid="validate.validations.rfc" />
                                  <FormInputText wrapperClass="field col-4" label="Contacto" v-model="entity.contact" :valid="validate.validations.contact" />
                                  <FormInputText wrapperClass="field col-8" label="Dirección" v-model="entity.address" :valid="validate.validations.address" />
                                  <FormInputText wrapperClass="field col-4" label="País" v-model="entity.country" :valid="validate.validations.country" />
                                  <FormInputText wrapperClass="field col-4" label="Estado" v-model="entity.state" :valid="validate.validations.state" />
                                  <FormInputText wrapperClass="field col-4" label="Ciudad" v-model="entity.city" :valid="validate.validations.city" />
                                  <FormInputText wrapperClass="field col-4" label="Colonia" v-model="entity.suburb" :valid="validate.validations.suburb" />
                                  <FormInputText wrapperClass="field col-1" label="CP" v-model="entity.zip_code" :valid="validate.validations.zip_code" />
                                  <FormInputText wrapperClass="field col-2" label="Telefono" v-model="entity.telephone" :valid="validate.validations.telephone" />
                                  <FormInputText wrapperClass="field col-4" label="Email Cobranza" v-model="entity.collection_email" :valid="validate.validations.collection_email" />
                                  <FormInputText wrapperClass="field col-4" label="Email Ventas" v-model="entity.sale_email" :valid="validate.validations.sale_email" />
                                  <FormSwitch wrapperClass="field col-1" label="Activo" v-model="entity.active" />
                              </div>
                            </Panel>
                        </TabPanel>
                        <TabPanel header="Cuentas Por Pagar">
                          <Panel header="Cuentas Por Pagar">
                            <div class="p-fluid formgrid grid">
                              <FormDropdownComplex :filterFields="['id_key', 'name']" :labelInOption="'id_key - name'" 
                              :labelInValue="'id_key - name'" :wrapperClass="'field col-6'" label="Cuenta Contable" v-model="entity.id_accounting_account" 
                              :options="ledgerAccounts" optionLabel="id_key" optionValue="id" />
                              <FormDropdown wrapperClass="field col-6" label="Concepto de Gasto" v-model="entity.id_dtp_concept" :options="dtpConcepts" :optionLabel="'name'" :optionValue="'id'" :clear="true" :filter="true"/>
                              <FormDropdown wrapperClass="field col-6" label="Condicion de Pago" v-model="entity.id_payment_condition" :options="paymentConditions" :optionLabel="'name'" :optionValue="'id'" :clear="true" :filter="true"/>
                            </div>
                          </Panel>
                        </TabPanel>
                    </TabView>
                </div>
            </div>

            <div class="col-12">
                <BasicDatatable v-if="id != 0" :rows="entities" :headers="headers" :rowaction="true" :rowdelete="true" :rowedit="true" @deleted="confirmDelete" @edited="edit" />
            </div>
            <DeleteDialog v-model="deleteDialog" @closed="deleteDialog = false" @deleted="deleted" />
        </div>
    </div>
</div>
</template>

<script>
import {
    Supplier
} from "../../../models/cxp/Supplier";
import {
    HeaderGrid,
    Rule,
    validate,
    fillObject,
    Toast,
    ErrorToast,
} from "../../../utilities/General";
import Helper from '../../../components/general/HelperDialog.vue';
import Loader from "../../../components/general/Loader.vue";
import BasicFormToolbar from "../../../components/general/BasicFormToolbar.vue";
import BasicDatatable from "../../../components/general/BasicDatatable.vue";
import DeleteDialog from "../../../components/general/DeleteDialog.vue";
import FormInputText from "../../../components/general/FormInputText.vue";
import FormDropdown from "../../../components/general/FormDropdown.vue";

import FormSwitch from "../../../components/general/FormInputSwitch.vue";
import Session from "../../../mixins/sessionMixin";
import {
    AccountingLedgerAccount
} from '../../../models/contabilidad/AccountingLedgerAccount';
import { DTPConcept } from '../../../models/cxp/DTPConcept';
import { PaymentCondition } from "../../../models/cxp/PaymentCondition";
import FormDropdownComplex from '../../../components/general/FormDropdownComplex.vue';

export default {
    mixins: [Session],
    props: {
        id: {
            type: [Number, String, null],
            default: null
        },
        invoice_issuing_entity: {
            type: [Object, null],
            default: null
        }
    },
    data() {
        return {
            entity: null,
            entities: [],
            ledgerAccounts: [],
            paymentConditions: [],
            dtpConcepts: [],
            deleteDialog: false,
            isHidden: true,
            rules: [
                new Rule({
                    name: "name"
                }),
                new Rule({
                    name: "key_name"
                })
            ],
            validate: {
                valid: false,
                validations: {
                    name: null,
                    key_name: null,
                    trade_name: null,
                    address: null,
                    suburb: null,
                    city: null,
                    state: null,
                    country: null,
                    zip_code: null,
                    telephone: null,
                    collection_name: null,
                    rfc: null,
                    curp: null,
                    contact: null,
                    sale_email: null,
                    id_accounting_account: null,
                    id_accounting_account_key: null,
                    id_accounting_account_name: null,
                    active: false
                },
            },
            headers: [
                new HeaderGrid("Nombre", "name"),
                new HeaderGrid("Clave", "key_name"),
            ],
            ledgerAccountHeaders: [new HeaderGrid('Folio', 'id'), new HeaderGrid('Clave', 'id_key'), new HeaderGrid('Nombre', 'name')],
            loading: false,
            ledgerAccountHelper: {
                visible: false,
            },
        };
    },
    components: {
        FormDropdownComplex,
        FormDropdown,
        FormSwitch,
        Loader,
        BasicFormToolbar,
        Helper,
        BasicDatatable,
        DeleteDialog,
        FormInputText
    },
    created() {
        console.log(this.session);
        this.entity = new Supplier(this.session);
    },
    async mounted() {
        // this.$forceUpdate();
        if (this.id) {
            this.entity = new Supplier(this.session);
            this.entity.id = this.id;
            let entity = this.entities.find(x => x.id == this.id);
            this.entity = fillObject(this.entity, entity);
        } else {
            if (this.invoice_issuing_entity) {
                this.entity = new Supplier(this.session);
                this.entity.name = this.invoice_issuing_entity.name;
                this.entity.rfc = this.invoice_issuing_entity.rfc;
                this.entity.trade_name = this.invoice_issuing_entity.name;
                this.entity.key_name = this.invoice_issuing_entity.name;
                this.entity.sale_email = this.invoice_issuing_entity.email;
                this.entity.collection_email = this.invoice_issuing_entity.email;
            }
        }
    },
    methods: {
        deleted() {
            this.deleteEntity();
        },
        openNew() {
            this.entity = new Supplier(this.session);
        },
        selectLedgerAccount(ledgeraccount) {
            this.entity.id_accounting_account = ledgeraccount.id;
            this.entity.id_accounting_account_key = ledgeraccount.id_key;
            this.entity.id_accounting_account_name = ledgeraccount.name;
        },
        async save() {
            try {
                //* Validacion de form
                this.loading = true;
                this.validate = validate(this.entity, this.rules);
                if (!this.validate.valid) {
                    throw "Favor de validar los campos";
                }
                //* Si el id es != 0 entonces actualizamos, si no, guardamos
                if (this.entity.id && this.entity.id > 0) {
                    //* Actualizamos
                    let entity = await this.entity.update();
                    this.$toast.add({
                        severity: "success",
                        summary: "Actualizar",
                        detail: "Informacion actualizada con exito",
                        life: 3000,
                    });
                    //* Modificamos el listado pah
                    let index = this.entities.findIndex((x) => x.id == this.entity.id);
                    this.entities[index] = entity;
                } else {
                    //* Creamos uno nuevo
                    let entity = await this.entity.save();
                    this.entity.id = entity.id;
                    //* Agregamos un dato extra
                    this.entities.push(entity);
                    this.$toast.add(
                        new Toast({
                            summary: "Creacion",
                            detail: "Informacion guardada con exito",
                        })
                    );
                }
                this.$emit('save', this.entity);
                this.entity = new Supplier(this.session);
            } catch (error) {
                this.$toast.add(new ErrorToast(error));
            } finally {
                this.loading = false;
            }
        },
        async edit(entity) {
            this.entity = fillObject(this.entity, entity);
        },
        confirmDelete(entity) {
            this.entity = fillObject(this.entity, entity);
            this.deleteDialog = true;
        },
        async deleteEntity() {
            try {
                this.loading = true;
                //* Eliminamos de la base
                await this.entity.delete();
                //* Eliminamos de la vista
                this.entities = this.entities.filter((val) => val.id !== this.entity.id);
                this.deleteDialog = false;
                //* Limpiamos la entidad
                this.entity = new Supplier(this.session);
                this.$toast.add({
                    severity: "success",
                    summary: "Eliminacion",
                    detail: "Registro eliminado con exito",
                    life: 3000,
                });
            } catch (error) {
                this.$toast.add(new ErrorToast(error));
            } finally {
                this.loading = false;
            }
        },
        async list() {
            this.loading = true;
            try {
                this.entities = await this.entity.all();
                this.ledgerAccounts = await new AccountingLedgerAccount(this.session).all();
                this.dtpConcepts = await new DTPConcept(this.session).all();
                this.paymentConditions = await new PaymentCondition(this.session).all();
            } catch (error) {
                this.$toast.add(new ErrorToast(error));
            } finally {
                this.loading = false;
            }
        },
    },
};
</script>

<style lang="scss" scoped>
.table-header {
    display: flex;
    justify-content: space-between;
}

.confirmation-content {
    display: flex;
    align-items: center;
    justify-content: center;
}

@media screen and (max-width: 960px) {
    ::v-deep(.p-toolbar) {
        flex-wrap: wrap;

        .p-button {
            margin-bottom: 0.25rem;
        }
    }
}
</style>
