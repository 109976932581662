import {
    Abstract
} from './Abstract';

export class PaymentCondition extends Abstract {
    constructor(session) {
        super('AccountsReceivable/PaymentCondition', session);
        this.name = null;
        this.key_name = null;
        this.days = 0;
        this.is_counted_payment = false;
        this.is_anticipated_payment = false;
    }
}